const date = new Date(); 

const dateFormatter = new Intl.DateTimeFormat("en-GB", {
  day: "2-digit",
  month: "long",
  year: "numeric",
});

const timeFormatter = new Intl.DateTimeFormat("en-GB", {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
  timeZoneName: "shortOffset",
});

export const newTime = new Date().getTime();
export const formattedDate = dateFormatter.format(date);
export const formattedTime = timeFormatter.format(date);