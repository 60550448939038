import React from "react";

export const Loading = () => (
    <>
      <div 
        key="loading" 
        className='vh-250 mt-4'
      >
        <p className="text-center fs-smaller">
          🧐 &nbsp; Abeg relax, make e load finish ...
        </p>
      </div>
    </>
  )